
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'

  import {
  LandingLayout,
  Button,
  Box,
  FlexSection,
  Flex,
  Jumbotron,
  Typography,
  ThinTile,
  WideTile,
  Emphasis,
  H1,
  H2,
  SectionHeader,
  NavBar,
} from "@redocly/developer-portal/ui";
import icon1 from "./images/icon1.png";
import quickstarticon from "./images/icons/Navy/VIB-CaseStudy-Location-Navy.png";
import apireferenceicon from "./images/icons/Navy/VIB-CaseStudy-Proposal-Navy.png";
import tutorialicon from "./images/icons/White/VIB-CaseStudy-Solution-White.png";

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = LandingLayout
  }
  render() {
    const props = this.props
    const components = { wrapper: React.Fragment };


    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)}
             components={components}>


  <NavBar location={props.location} standalone={true} />
  <Jumbotron>
  <H1 >Welcome to Totogi API Platform Docs! </H1>
    <Flex  px= {250} justifyContent="center">
    <Typography color="white" fontSize="18px"> Here you’ll find guides, API documentation, tutorials and use cases you need to build your applications. 
    <Emphasis> Let’s get started! </Emphasis></Typography>
  </Flex>
<Flex py={1} justifyContent="center" alignContent="space-between">
<ThinTile  to={'/developer-portal/'} icon={quickstarticon} header="Quickstart" >
  Learn about how you can get Access Keys and start with your first API request. 
</ThinTile>
<ThinTile  to="developer-portal/apireferences" icon={apireferenceicon} header="API References" >
  Explore our continuously growing API library, experiment them directly within the documentation.
</ThinTile>
<ThinTile  bgColor="#ff4f58" color="white" icon={tutorialicon} header="Tutorials" >
 coming soon!
 </ThinTile>
</Flex>
</Jumbotron>
<Box my={25}>
  <SectionHeader>
    Create your <Emphasis> telco app </Emphasis> today!
  </SectionHeader>
</Box>
           </MDXTag>
  }
}
MDXContent.isMDXComponent = true
  